.custom-hover-btn {
    background-color: transparent;
    color: var(--default-color);
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-hover-btn:hover {
    background-color: var(--hover-bg-color) !important;
    color: var(--hover-color) !important;
  }
  