.ant-tabs-nav-wrap{
    z-index: 99;
}


  .delete-button:hover {
    background-color: #f5222d; /* Darker red color on hover */
    transform: scale(1.1);  /* Scale the button to 110% of its size */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);  /* Add shadow effect for depth */
  }
  
  .delete-button:hover .anticon {
    color: #fff;  /* Change the icon color to white */
  }
  
  /* Optional: Add a subtle animation effect when clicking */
  .delete-button:active {
    transform: scale(0.98);  /* Slight shrink effect when button is clicked */
  }
  