.custom-tabs .ant-tabs-nav {
    background: #f0f0f0;
    padding: 0.5rem;
    border-radius: 8px;
  }
  
  .custom-tabs .ant-tabs-tab {
    background: white;
    padding: 0.25rem 1rem;
    border-radius: 6px;
    margin-right: 0.5rem;
    font-weight: 500;
    transition: all 0.3s;
    border: 1px solid #d9d9d9;
  }
  
  .custom-tabs .ant-tabs-tab:hover {
    background: #e6f7ff;
    border-color: #1890ff;
  }
  
  .custom-tabs .ant-tabs-tab-active {
    background: #d5d5e6;
    color: white;
    border-color: #d5d5e6;
  }
  